.container-one{
    margin-left: 15%;
    display: flex;
    height:300px;
}

.left{
    flex: 1;
}

.right{
    flex: 1;
    align-self: center
}

.container-two{
    margin-left: 15%;
    display: flex;
    flex-direction: column;
    background-color: ;
    height: 100%;
}

.text_foil_query{
    margin-left: 40px;
    display: flex;
    margin-top: 10px
}

.input{
    margin-left: 40px;
    flex-grow: 1;
    margin: 10px;
    margin-top: 4px;
    height: 20px;
}

.submit-query{
    background-color:
            RGB(228, 146, 60);
    height:120px;
    width: 300px;
}