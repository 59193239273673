.r2r{
    margin-left: 11%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    background-color: ;
    height: 100%;
}

.fields{
  margin-left: 33.33%;
  display: flex;
  flex-direction: column;
  width: 33.33%;
  background-color: ;
}

.text{
  display: flex;
  margin-left: 30;
  margin-top: 10px

}

.submit{
  heigth: 50px;
  width: 150px;
  margin-left: 11%;
  background-color:
  RGB(228, 146, 60);
  margin-top: 30px;
  margin-bottom: 30px;
}
