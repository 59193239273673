body {
  background-color: #282c34;
  color: white;
  margin-top: 100px;
  font-family: Andale Mono, monospace;
  text-align: center;
}
.logo{
  margin-top: 20px;
  margin-left: 14.5%;
  width: 300px;
  margin-bottom: 30px
}

.sidebar {
  height: 100%;
  display: flex;
  width: 15.2%;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 40px;
  background-color: RGB(228, 146, 60);
}

.h1-style{
  margin-left: 15%;
}

.button{
  margin: 2px;
  width: 200px;
  height: 27px;
}

.content{
  margin-left: 250px;
  margin-top: 75px;
}

.dropdown{
  margin: 10px;
}

.signout {
  position: absolute;
  right: 0;
  top: 0;
}

.toggle-sidebar{
  position: absolute;
  left: 290px;
  top: 0;
}

.flex-input{
  background-color: red;
  display: flex;
  flex-wrap: wrap;
  margin-left: 35%;
  align-items: left;
  justify-content: center;
  width: 40%;
}

.text{
  display: flex;
  margin-left: 30;
  margin-top: 10px
}


