.foil{
    margin-left: 11%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    background-color: ;
    height: 100%;
}

.blue-category{
  display: flex;
  flex-direction: column;
  width: 33.33%;
  background-color: ;
}

.red_category{
  display: flex;
  flex-direction: column;
  width: 33.33%;
  background-color:;
}

.green-category{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ;
  width: 33.33%;
}

.input{
  width: 515px;
  flex-grow: 1;
  margin: 10px;
  margin-top: 10px;
  height: 20px
}

.text{
  display: flex;
  margin-left: 30;
  margin-top: 10px
}

.success{
    color: RGB(228, 146, 60);
}

.submit{
  heigth: 50px;
  width: 150px;
  margin-left: 11%;
  background-color:
  RGB(228, 146, 60);
  margin-top: 30px;
  margin-bottom: 30px;
}
